import React, { FC } from "react";

export const ThanksPage: FC = () => {
  return (
    <div className="container">
      <section className="copy">
        <p>THANK YOU FOR UPDATING YOUR DETAILS</p>
        <p>You have been entered for your chance to win 1 of 3 $500 vouchers</p>

        <a className="button" href="https://georgjensen.com">
          Explore Georg Jensen Online
        </a>
      </section>
    </div>
  );
};
